module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-do_hbjxh4gmthibpzgcrrj575pcfy/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://looparch.com"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_kkbq55ywotpecz7w5oag5iyvaq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Loop Architectural Materials","short_name":"Loop","start_url":"/","background_color":"#F3F8F1","theme_color":"#83B668","icon":"src/images/loop_icon.png","icon_options":{"purpose":"any maskable"},"display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ed7629d334d690c660c7a36e65e02938"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.3_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3._7x475a7slkxd6bx4olkfnbrsnq/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_kuapo6m5hm56zojnif2ndipk7a/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3GR7L59SYP"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__4toogyd7gjapwitl7ixp3hsdjm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
