exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-announcements-tsx": () => import("./../../../src/pages/announcements.tsx" /* webpackChunkName: "component---src-pages-announcements-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-featured-products-tsx": () => import("./../../../src/pages/featured-products.tsx" /* webpackChunkName: "component---src-pages-featured-products-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-announcement-tsx": () => import("./../../../src/templates/announcement.tsx" /* webpackChunkName: "component---src-templates-announcement-tsx" */),
  "component---src-templates-manufacturer-tsx": () => import("./../../../src/templates/manufacturer.tsx" /* webpackChunkName: "component---src-templates-manufacturer-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "slice---src-components-algolia-search-tsx": () => import("./../../../src/components/algolia-search.tsx" /* webpackChunkName: "slice---src-components-algolia-search-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-navbar-tsx": () => import("./../../../src/components/navbar.tsx" /* webpackChunkName: "slice---src-components-navbar-tsx" */)
}

